import { useEffect, useState } from "react";
import { LANGUAGES } from "../constants";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";


function Header({ headerH2, headerH2_Line2, headerText }) {
  const [show, setShow] = useState(true);
  const { i18n,t } = useTranslation();
 
  const [heading, setHeading] = useState({
    line1: "verification_line_1",
    line2: "verification_line_2",
    line3: "verification_line_3"
  })
  useEffect(() => {
   console.log(window.location.pathname.split("/").at(-1))
   switch (window.location.pathname.split("/").at(-1)) {
    case "verification-failed":
      setHeading({
        line1: "Twelve Million Plus",
        line2: "verificationFailed_line_2",
        line3: "verificationFailed_line_3"
      })
      break;
    case "registration":
      setHeading({
        line1: "registration_line_1",
        line2: "",
        line3: "registration_line_3"
      })
      break;
    case "login":
      setHeading({
        line1: "login_line_1",
        line2: "Twelve Million Plus",
        line3: "login_line_3"
      })
      break;
    case "profile":
      setHeading({
        line1: "profile_line_1",
        line2: "",
        line3: "profile_line_3"
      })
      break;
    case "email":
      setHeading({
        line1: "login_line_1",
        line2: "Twelve Million Plus",
        line3: "login_line_3"
      })
      break;
    default:
      setHeading({
        line1: "verification_line_1",
        line2: "verification_line_2",
        line3: "verification_line_3"
      })
   }
  },[])


  const onChangeLanguage = (e) => {
    e.preventDefault();
    console.log("e.target.value", e.target.value);
    const language = e.target.value;
    i18n.changeLanguage(language);
  };
  return (
    <main className="h-screen flex xs:flex-col"  >
      
      <div className="bg-community-pattern bg-cover  xs:w-screen h-screen xs:h-3/5 xs:pt-8 w-1/2 flex flex-col items-center justify-center mb-8  ">

      <img
        src={"/../images/logo-full.png"}
        alt="logo"
        className="mt-[5%] xs:w-20 xs:h-20 w-[208px] h-[208px] justify-center"
      />
      {!window.location.hostname.includes("sso.twelvemillionplus.com") && (
        <>
        <button 
        onClick={() => {setShow(!show)}}
        className="text-left self-start pl-6">X Close</button>
        <div className={show ? "bg-red-500 text-white font-extrabold px-8 py-4 ": " hidden"}>
        <h1 className="text-center text-5xl"
        >
          SSO STAGING ENVIROMENT
        </h1>
        </div>
        </>
        
      )}
      <div className="absolute left-4 top-4">
        {/* <select className="rounded-md border-2" defaultValue={i18n.language} onChange={onChangeLanguage}>
        {LANGUAGES.map(({label, code}) => (
          <option key={code} value={code}>
            {label}
          </option>
        )
        )}
        </select> */}
      </div>
      <h4 className="xs:text-xl text-5xl text-community-text-primary font-inter text-center font-[700]">
        {t(heading.line1)}
      </h4>
      <h4 className="xs:text-xl xs:pb-2 text-5xl text-community-text-primary font-inter text-center  font-[700] pb-6">
        {t(heading.line2)}
      </h4>
      <p className="xs:text-base leading-[25px] font-[500] text-[20px] text-community-text-regular-primary  text-center font-inter w-[80%]">
        {t(heading.line3)}
      </p>
      
      </div>
      <div className="xs:w-screen xs:h-full w-1/2 flex flex-col gap-6 justify-center  ">
        <Outlet />
      </div>
    </ main>
  );
}

export default Header;
